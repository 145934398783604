import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SelectionGroup, Checkbox, RadioButton } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const SelectionGroupExample = () => {
  const [checkedItems, setCheckedItems] = React.useState({
    "v-checkbox1": true,
    "v-checkbox2": true
  });
  const onChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };
  return <SelectionGroup label="Selection group" mdxType="SelectionGroup">
      <Checkbox id="v-checkbox1" name="v-checkbox1" label="Option" checked={checkedItems["v-checkbox1"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox2" name="v-checkbox2" label="Option" checked={checkedItems["v-checkbox2"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox3" name="v-checkbox3" label="Option" checked={checkedItems["v-checkbox3"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox4" name="v-checkbox4" label="Option" checked={checkedItems["v-checkbox4"]} onChange={onChange} mdxType="Checkbox" />
    </SelectionGroup>;
};
export const VerticalGroupExample = () => {
  const [checkedItems, setCheckedItems] = React.useState({
    "v-checkbox5": true,
    "v-checkbox6": true
  });
  const onChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };
  return <SelectionGroup label="Vertical group" mdxType="SelectionGroup">
      <Checkbox id="v-checkbox5" name="v-checkbox5" label="Option" checked={checkedItems["v-checkbox5"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox6" name="v-checkbox6" label="Option" checked={checkedItems["v-checkbox6"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox7" name="v-checkbox7" label="Option" checked={checkedItems["v-checkbox7"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox8" name="v-checkbox8" label="Option" checked={checkedItems["v-checkbox8"]} onChange={onChange} mdxType="Checkbox" />
    </SelectionGroup>;
};
export const HorizontalGroupExample = () => {
  const [selectedItem, setSelectedItem] = React.useState("1");
  const onChange = event => {
    setSelectedItem(event.target.value);
  };
  return <SelectionGroup label="Horizontal group" direction="horizontal" mdxType="SelectionGroup">
      <RadioButton id="h-radio1" name="h-radio" value="1" label="Option" checked={selectedItem === "1"} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="h-radio2" name="h-radio" value="2" label="Option" checked={selectedItem === "2"} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="h-radio3" name="h-radio" value="3" label="Option" checked={selectedItem === "3"} onChange={onChange} mdxType="RadioButton" />
    </SelectionGroup>;
};
export const _frontmatter = {
  "slug": "/components/selection-group",
  "title": "SelectionGroup",
  "navTitle": "SelectionGroup"
};
const layoutProps = {
  SelectionGroupExample,
  VerticalGroupExample,
  HorizontalGroupExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SelectionGroupExample mdxType="SelectionGroupExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Currently, Selection group can contain either Checkboxes or Radio buttons.`}
        <ul parentName="li">
          <li parentName="ul">{`If the user can select more than one option from a list, use `}<InternalLink href="/components/checkbox" mdxType="InternalLink">{`checkboxes.`}</InternalLink></li>
          <li parentName="ul">{`If the user can only select one option from a list, use `}<InternalLink href="/components/radio-button" mdxType="InternalLink">{`radio buttons.`}</InternalLink></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Default option must be set for Radio button groups.`}</strong>{` For Checkbox groups, this is recommended.`}</li>
      <li parentName="ul">{`Visible label must be given to the Selection group. The label should be clear and describe the choice the user is about to make.`}</li>
      <li parentName="ul">{`Selection group can be aligned both vertically and horizontally. `}<strong parentName="li">{`Vertical alignment is recommended.`}</strong>{` Horizontal alignment can be used when there are less than 4 options.`}
        <ul parentName="li">
          <li parentName="ul">{`Note that horizontal Selection groups must not span to multiple lines.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "vertical-group",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical group`}<a parentName="h4" {...{
        "href": "#vertical-group",
        "aria-label": "vertical group permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Vertical selection group is the recommended way to present grouped form controls. Vertical checkbox groups are generally easier to perceive and read.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <VerticalGroupExample mdxType="VerticalGroupExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "horizontal-group",
      "style": {
        "position": "relative"
      }
    }}>{`Horizontal group`}<a parentName="h4" {...{
        "href": "#horizontal-group",
        "aria-label": "horizontal group permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Horizontal selection group can be used in situations where there are only a few (less than 4) options. Otherwise, a vertical alignment is recommended.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <HorizontalGroupExample mdxType="HorizontalGroupExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      